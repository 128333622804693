
// COLLAPSING NAV MENU
$(document).ready(function() {

    var
    expanded = false,
    toggleButton = $('#mobile-menu-toggle'),
    primaryNav = $('#primary-navigation'),
    toggleMenu = function() {
        if(expanded) {
            primaryNav.removeClass('expanded');
        }
        else {
            primaryNav.addClass('expanded');
        }
        expanded = !expanded;
    };

    toggleButton.click(toggleMenu);

});
